<template>
  <q-page class="q-pl-xs q-pr-xs q-pl-md q-pr-md" v-if="customer">
    <div class="row items-center q-gutter-md justify-center">

    <q-card class="my-card profile-card">
      <q-card-section align="center">
        <q-icon name="account_circle" color="grey" size="100px"/>
        <br>
        <span class="text-bold">
          {{ customer.get('first_name') + ' ' + customer.get('last_name') }}
        </span>
        <br>
        <span>
          {{ currentAddress.street }} {{ currentAddress.county ? ', ' + currentAddress.county : ''}}
        </span>
      </q-card-section>
      <q-separator/>
      <q-card-section>
        <span class="text-bold text-subtitle1">Personal Details</span>
        <br>
        <customer-details :customer="customer"/>
      </q-card-section>
      <q-separator/>

      <q-card-section>
        <span class="text-bold text-subtitle1">E-mail addresses</span>
        <add-email />
        <br>
        <customer-emails :emails="customer.emails()"/>
      </q-card-section>
      <q-separator/>

      <q-card-section >
        <span class="text-bold text-subtitle2"> Phone Numbers</span>
        <add-phone/>
        <br>
        <customer-phones :phones="customer.phones()"/>
        </q-card-section>
    </q-card>
    </div>

  </q-page>
</template>

<script>
import CustomerDetails from '../components/CustomerDetails'
import CustomerEmails from '../components/CustomerEmails'
import CustomerPhones from '../components/CustomerPhones'
import AddEmail from '../components/AddEmail'
import AddPhone from '../components/AddPhone'
export default {
  name: 'PageCustomer',
  meta: {
    title: 'Customer Details'
  },
  components: { CustomerDetails, CustomerEmails, CustomerPhones, AddEmail, AddPhone },
  async mounted () {
    this.$dispatch({
      action: 'customer/getCustomer',
      message: 'Loading Customer Details...'
    })
  },
  computed: {
    customer () {
      return this.$store.state.customer.customer
    },
    addresses () {
      return this.customer.get('addresses')
    },
    currentAddress () {
      return this.customer.currentAddress()
    }
  }
}
</script>

<style scoped>
  .desktop .profile-card {
    width: 650px;
  }
</style>
