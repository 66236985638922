<template>
  <div>
    <q-field borderless>
      <q-input :value="customer.fullName()" label="Full Name" borderless readonly/>
    </q-field>
    <q-field borderless>
      <q-input :value="customer.get('dob') | date" label="Date of Birth" borderless readonly/>
    </q-field>
    <!--<q-field borderless label="Address">
      <q-input :key="i" v-for="(address, i) in customer.get('addresses')"
               :value="addressFormat(address)"  borderless readonly></q-input>
    </q-field>-->

    <div :key="i" v-for="(addressType, i) in types">
      <div v-if="getAddress(addressType).length > 0">
        <div class="text-grey-8">{{addressType }}
        <!-- <q-icon v-if="addressType==='Current Address'" :name="editingAddress ? 'cancel':'edit'" style="position: relative;bottom:2px; cursor:pointer;" @click="editingAddress=!editingAddress"></q-icon> -->

        </div>
        <!-- <address-editor v-if="editingAddress && addressType==='Current Address'" @saved="editingAddress=false"></address-editor> -->
        <q-list>
          <q-item :key="index" v-for="(address, index) in getAddress(addressType)" :style="{padding: '0px'}">
            <q-item-section>
              <q-item-label lines="1">{{ addressFormat(address) }}</q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </div>
    </div>
  </div>
</template>

<script>
// import AddressEditor from './AddressEditor'
export default {
  name: 'CustomerDetails',
  // components: { AddressEditor },
  props: {
    customer: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      types: [
        'Current Address',
        'Previous'
      ],
      editingAddress: false
    }
  },
  methods: {
    addressFormat (address) {
      return `${address.number} ${address.street}, ${address.county}, ${address.postcode}`
    },
    getAddress (type) {
      return type === 'Current Address' ? this.current : this.others
    }
  },
  computed: {
    current () {
      return this.customer.get('addresses').filter(a => a.is_current)
    },
    others () {
      return this.customer.get('addresses').filter(a => !a.is_current)
    }

  }
}
</script>

<style>
</style>
