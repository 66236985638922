<template>
<div>

  <q-dialog v-model="confirmPhoneDialog" persistent @hide="onHide">
      <q-card v-if="selectedPhone">
        <q-card-section class="row items-center phone-ver">
          <span class="q-ml-sm">{{selectedPhone.number()}} cannot be set as default before it is verified</span>
          <q-field v-if="verCodeSent">
            <q-input v-model="verificationCode" placeholder="Enter Code"></q-input>
          </q-field>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="Cancel" color="primary" v-close-popup/>
          <q-btn v-if="!verCodeSent" flat label="Send Verification Code" color="primary" @click="sendVerificationCode(selectedPhone)"/>
          <q-btn v-if="verCodeSent" flat label="Verify Phone" color="primary" @click="verifyPhone(selectedPhone)"/>
        </q-card-actions>
      </q-card>
    </q-dialog>

  <div :key="i" v-for="(phoneType, i) in types">
    <div v-if="getPhones(phoneType).length > 0">
      <span class="text-blue">{{phoneType }}</span>
      <q-list>
        <q-item :key="index" v-for="(phone, index) in getPhones(phoneType)">
          <q-item-section avatar v-if="phoneType==='Mobile'">
            <q-radio :val="phone"
                     :value="primaryMobile"
                     @input="setDefault(phone)" />
          </q-item-section>

          <q-item-section>
            <q-item-label lines="1"> {{ phone.number() }}</q-item-label>
          </q-item-section>

          <q-item-section avatar>
            <boolean-icon :value="phone.get('confirmed')"/>
          </q-item-section>
        </q-item>
      </q-list>
    </div>
  </div>

</div>

</template>

<script>
import { required, numeric, minLength, maxLength } from 'vuelidate/lib/validators'

export default {
  name: 'CustomerPhones',
  props: {
    phones: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      selectedPhone: null,
      // confirmDelete: false,
      confirmPhoneDialog: false,
      verCodeSent: false,
      verificationCode: '',
      types: [
        'Home',
        'Work',
        'Mobile'
      ]
    }
  },
  validations: {
    verificationCode: {
      required,
      numeric,
      minLength: minLength(5),
      maxLength: maxLength(5)
    }
  },
  computed: {
    primaryMobile () {
      return this.phones.find(p => p.isPrimaryMobile())
    }
  },
  methods: {
    // deleteConfirm (phone) {
    //   this.confirmDelete = true
    //   this.selectedPhone = phone
    // },
    // deletePhone (phone) {
    //   this.selectedPhone = null
    //
    //   this.$dispatch({
    //     action: 'customer/deletePhone',
    //     params: phone.id(),
    //     message: `Deleting phone ${phone.number()}`,
    //     successMessage: `Successfully deleted phone ${phone.number()}`
    //   })
    //
    //   this.confirmDelete = false
    // },
    getPhones (type) {
      return this.phones.filter(p => p.type() === type)
    },
    onHide () {
      this.selectedPhone = null
      this.verCodeSent = false
    },
    async sendVerificationCode (phone) {
      await this.$dispatch({
        action: 'customer/sendMobileVerification',
        params: phone,
        message: 'Sending Verification code to ' + phone.number(),
        successMessage: 'Verification code was sent to ' + phone.number()
      })

      this.verCodeSent = true
    },
    async verifyPhone (phone) {
      if (this.$v.verificationCode.$invalid) {
        return
      }

      var success = await this.$dispatch({
        action: 'customer/verifyMobile',
        params: { phone, code: this.verificationCode },
        message: 'Verifying Code...',
        successMessage: 'Mobile phone Successfully verified and set as default'
      })

      if (success) {
        this.$store.commit('customer/setDefaultPhone', phone.id(), {
          root: true
        })

        this.confirmPhoneDialog = false
      }
    },
    setDefault (phone) {
      if (!phone.get('confirmed')) {
        this.confirmPhoneDialog = true
        this.selectedPhone = phone
        return
      }

      if (phone.isPrimaryMobile()) return

      this.$dispatch({
        action: 'customer/setDefaultPhone',
        params: phone,
        message: `Setting default mobile as ${phone.get('number')}`,
        successMessage: `Successfully set default mobile as ${phone.get('number')}`
      })
    }
  }
}

</script>

<style>
  .phone-ver {
    max-width:300px;
  }
  .phone-ver input {
    width:100px;
  }
</style>
