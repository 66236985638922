<template>
  <div>
    <q-card-actions class="absolute-top-right">
      <q-btn id="btn_add_email" @click="emailForm = true" class="text-light-blue-7" outline rounded label="+ Add Email"/>
    </q-card-actions>

    <q-dialog v-model="emailForm" :full-width="$q.screen.xs">
      <q-card class="dialog-width">

        <form @submit.prevent="addEmail">
          <q-card-section class="text-center">
            <br>
            <q-icon name="email" color="light-blue-5" size="md"/>
            <br>
            <span class="text-h6"> Add Email</span>
            <br>
          </q-card-section>

          <q-card-section>
            <q-field class="card" icon="lock">
              <q-input v-model.trim="$v.form.address.$model" label="Email Address" outlined/>
            </q-field>
            <span class="text-red"
                  v-if="submitted && !$v.form.address.email">Please enter a valid email address</span>
            <span class="text-red"
                  v-else-if="submitted && !$v.form.address.required">The address field is required</span>
            <br>
            <q-field class="card" icon="lock">
              <q-select v-model="form.type" :options="emailTypes" label="Email Type" required outlined/>
            </q-field>
          </q-card-section>

          <q-card-actions class="row full-width">
            <q-btn rounded class="col text-light-blue-7 outlineColor" outline label="Cancel" @click="resetForm" v-close-popup/>
            <q-btn rounded class="col text-white bgBtnLiner"   type="submit" label="Add"/>
          </q-card-actions>
          <br>
        </form>
      </q-card>

    </q-dialog>
  </div>
</template>

<script>
import { email, required } from 'vuelidate/lib/validators'

export default {
  name: 'AddEmail',
  data () {
    return {
      emailForm: false,
      submitted: false,
      form: {
        address: '',
        type: ''
      },
      emailTypes: [
        {
          label: 'Home',
          value: 1
        },
        {
          label: 'Work',
          value: 2
        },
        {
          label: 'Other',
          value: 3
        }
      ]
    }
  },
  validations: {
    form: {
      address: {
        email,
        required
      }
    }
  },
  mounted () {
    this.form.type = this.emailTypes[0]
  },
  methods: {
    async addEmail () {
      if (this.$v.form.$invalid) {
        this.submitted = true
        return false
      }

      var success = await this.$dispatch({
        action: 'customer/addEmail',
        params: this.form,
        message: 'Adding Email...',
        successMessage: 'Email was added successfully'
      })

      if (success) {
        this.emailForm = false
        this.resetForm()
        window.dataLayer.push({ event: 'added_email' })
      }
    },
    resetForm () {
      this.form = {
        address: '',
        type: this.emailTypes[0]
      }
      this.submitted = false
    }
  }
}
</script>

<style>
  .dialog-width {
    width: 50vw;
  }
  .outlineColor {
    color: #1C9EDB;
  }
</style>
