<template>
  <div>
    <q-dialog v-model="confirm" persistent>
      <q-card v-if="selectedEmail">
        <q-card-section class="row items-center">
          <span class="q-ml-sm">Note: Your portal login email will be set to {{selectedEmail.get('address')}}</span>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="Cancel" color="primary" v-close-popup/>
          <q-btn flat label="Change Default Email" color="primary" @click="setDefault(selectedEmail)" v-close-popup/>
        </q-card-actions>
      </q-card>
    </q-dialog>

    <q-dialog v-model="confirmEmailDialog" persistent>
      <q-card v-if="selectedEmail">
        <q-card-section class="row items-center">
          <span class="q-ml-sm">{{selectedEmail.get('address')}} cannot be set as default before it is verified</span>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="Cancel" color="primary" v-close-popup/>
          <q-btn flat label="Verify Email" color="primary" @click="verifyEmail(selectedEmail)" v-close-popup/>
        </q-card-actions>
      </q-card>
    </q-dialog>

    <div :key="i" v-for="(emailsType, i) in types">
      <div v-if="getEmails(emailsType).length > 0">
      <span class="text-blue">{{emailsType }}</span>
      <q-list>
        <q-item :key="index" v-for="(email, index) in getEmails(emailsType)">
          <q-item-section avatar>
            <q-radio  :val="email" :value="defaultEmail"
                        @input="confirmDefault(email)"/>
          </q-item-section>

          <q-item-section>
            <q-item-label lines="1"> {{ email.get('address') }}</q-item-label>
          </q-item-section>

          <q-item-section avatar>
            <boolean-icon :value="!!email.get('confirmed')"/>
          </q-item-section>
        </q-item>
      </q-list>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomerEmails',
  props: {
    emails: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      selectedEmail: null,
      confirm: false,
      confirmEmailDialog: false,
      types: [
        'Home', 'Work'
      ]
      // confirmDelete: false,
    }
  },
  computed: {
    defaultEmail () {
      return this.emails.find(e => e.isDefault())
    }
  },
  methods: {
    // deleteConfirm (email) {
    //   if (email.get('is_default')) return
    //
    //   this.confirmDelete = true
    //   this.selectedEmail = email
    // },
    // deleteEmail (email) {
    //   this.selectedEmail = null
    //
    //   this.$dispatch({
    //     action: 'customer/deleteEmail',
    //     params: email.id(),
    //     message: `Deleting email ${email.get('address')}`,
    //     successMessage: `Successfully deleted email ${email.get('address')}`
    //   })
    //
    //   this.confirmDelete = false
    // },
    getEmails (type) {
      return this.emails.filter(e => e.type() === type)
    },
    verifyEmail (email) {
      this.$dispatch({
        action: 'customer/sendVerificationEmail',
        params: email.get('address'),
        message: 'Sending Verification email to ' + email.get('address'),
        successMessage: 'We have sent an email verification to ' + email.get('address') + '. Click the link in the email to complete your account activation process.'
      })
    },
    setDefault (email) {
      if (email.get('is_default')) return

      this.$dispatch({
        action: 'customer/setDefaultEmail',
        params: email,
        message: `Setting default email as ${email.get('address')}`,
        successMessage: `Successfully set default email as ${email.get('address')}`
      })
    },
    confirmDefault (email) {
      if (email.get('is_default')) return

      this.selectedEmail = email

      if (!email.get('confirmed')) {
        this.confirmEmailDialog = true
        return
      }

      this.confirm = true
    }
  }
}
</script>

<style>
</style>
