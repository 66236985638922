<template>
  <div>
    <q-card-actions class="absolute-top-right">
      <q-btn id="btn_add_phone" @click="phoneForm = true" class="text-light-blue-7" outline rounded label="+ Add Phone"/>
    </q-card-actions>

    <q-dialog v-model="phoneForm" :full-width="$q.screen.xs">
      <q-card :class="$q.screen.gt.xs ? 'dialog-width' : ''">
        <form @submit.prevent="addPhone">
          <q-card-section class="text-center">
            <br>
            <q-icon name="phone" color="light-blue-5" size="md"/>
            <br>
            <span class="text-h6"> Add Phone</span>
            <br>
          </q-card-section>

          <q-card-section>
            <q-field class="card" icon="lock">
              <q-input v-model.trim="$v.form.phone.$model" type="tel" mask="+44############" label="Phone Number" outlined/>
            </q-field>
            <span class="text-red"
                  v-if="submitted && $v.form.phone.required && $v.form.phone.$invalid">Please enter a valid phone number</span>
            <span class="text-red"
                  v-else-if="submitted && !$v.form.phone.required">The phone number field is required</span>
            <br>
            <q-field class="card" icon="lock">
              <q-select v-model="form.type" :options="phoneTypes" label="Phone Type" outlined/>
            </q-field>
          </q-card-section>

          <q-card-actions class="row full-width">
            <q-btn rounded class="col text-light-blue-7 outlineColor" outline label="Cancel" @click="resetForm" v-close-popup/>
            <q-btn rounded class="col text-white bgBtnLiner"   type="submit" label="Add"/>
          </q-card-actions>
          <br>
        </form>
      </q-card>

    </q-dialog>

  </div>
</template>

<script>
import { required, minLength, maxLength } from 'vuelidate/lib/validators'

export default {
  name: 'AddPhone',
  data () {
    return {
      phoneForm: false,
      submitted: false,
      form: {
        phone: '',
        type: ''
      },
      phoneTypes: [
        {
          label: 'Home',
          value: 1
        },
        {
          label: 'Work',
          value: 2
        },
        {
          label: 'Mobile',
          value: 3
        },
        {
          label: 'Other',
          value: 4
        }
      ]
    }
  },
  validations: {
    form: {
      phone: {
        required,
        minLength: minLength(13),
        maxLength: maxLength(13)
      }
    }
  },
  mounted () {
    this.form.type = this.phoneTypes[2]
  },
  methods: {
    resetForm () {
      this.form = {
        phone: '',
        type: this.phoneTypes[2]
      }
      this.submitted = false
    },
    async addPhone () {
      if (this.$v.form.$invalid) {
        this.submitted = true
        return
      }

      var success = await this.$dispatch({
        action: 'customer/addPhone',
        params: this.form,
        message: 'Adding Phone...',
        successMessage: 'Phone has been added'
      })

      if (success) {
        this.phoneForm = false
        this.resetForm()
        window.dataLayer.push({ event: 'added_phone' })
      }
    }
  }
}
</script>

<style scoped>
  .dialog-width {
    width: 50vw;
  }
</style>
